import darkImage from './images/logo-dark.png';
import lightImage from './images/logo-light.png';
import darkHeader from './images/header-dark.jpg';
import lightHeader from './images/header-light.jpg';
import simpleParallax from 'simple-parallax-js';

const background = document.querySelector('.header > .background > img');
const image = document.querySelector('.header > .image > img');
const modalImage = document.querySelector('.modal > .wrapper > .left > img');

new simpleParallax(background, {
  scale: 1.2
});

function checkTime() {
  const hour = new Date().getHours();
  const currentTheme = document.documentElement.className.includes('dark') ? 'dark' : 'light';

  if (hour >= 20 || hour < 8) {
    if (currentTheme !== 'dark') {
      background.src = darkHeader;
      image.src = darkImage;
      modalImage.src = darkImage;
      document.documentElement.className = "dark";
    }
  } else if (currentTheme === 'dark') {
    background.src = lightHeader;
    image.src = lightImage;
    modalImage.src = lightImage;
    document.documentElement.className = "";
  }
}

window.onload = checkTime;
setInterval(checkTime, 60000)

const modal = document.querySelector('.modal');
const modalContent = modal.querySelector('.wrapper')
const modalClose = modal.querySelector('header > button')

let scrollPos = 0;

window.showModal = () => {
  scrollPos = document.scrollingElement.scrollTop;
  modal.classList.add('open');

  document.body.classList.add('modal-open');
}

modal.addEventListener('click', (event) => {
  if (modalContent.contains(event.target) || modalContent.isSameNode(event.target)) {
    return;
  }

  modal.classList.remove('open');
  document.body.classList.remove('modal-open');

  document.scrollingElement.scrollTo(0, scrollPos);
});

modalClose.addEventListener('click', (event) => {
  event.preventDefault();

  modal.classList.remove('open');
  document.body.classList.remove('modal-open');

  document.scrollingElement.scrollTo(0, scrollPos);
});